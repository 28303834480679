.dialogWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--light-clr);
  box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .closeBtn {
    position: absolute;
    top: 16px;
    right: 16px;
    filter: invert(100%);
  }

  .descriptionSection {
    background-color: var(--light-clr);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 150px 40px 150px;
    justify-content: center;
    .avatar {
      margin-bottom: 12px;
    }
    .header {
      font-weight: 600;
      font-size: 18px;
      display: flex;
      flex-direction: column;
      align-items: center;
      white-space: pre-line;
      justify-content: center;
      margin-bottom: 12px;
      p {
        text-align: center;
      }
    }
    .subHeader {
      font-weight: 600;
      white-space: pre-line;
      font-size: 12px;
      color: #666666;
      margin-bottom: 12px;
      text-align: center;
      .highlight {
        font-size: 14px;
        color: red;
      }
    }
    .discountButton {
      margin-top: 12px;
      button {
        font-size: 15px;
      }
    }
    .reasonChoose {
      padding: 15px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .actionsGroup {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      button {
        margin-bottom: 10px;
        cursor: pointer;
      }
      width: 100%;
    }
  } 
}

@media screen and (max-width: 767px) {
  .dialogWrapper .descriptionSection {
    padding: 50px 30px;
    .header {
      font-size: 14px;
    }
    .subheader {
      font-size: 10px;
      .highlight {
        font-size: 12px;
      }
    }
    .actionsGroup {
      button {
        font-size: 12px;
      }
    }
  }
}

.smallPrint {
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
  flex-wrap: wrap;
  white-space: pre-wrap;
  color: #666666;
  font-weight: 500;
  .link {
    color: #0abc71;
  }
  p {
    text-align: center;
  }
}


@media screen and (max-width: 360px) {
  .dialogWrapper .descriptionSection {
    padding: 50px 30px;
    .header {
      font-size: 12px;
    }
    .subheader {
      font-size: 9px;
      .highlight {
        font-size: 11px;
      }
    }
    .actionsGroup {
      button {
        font-size: 11px !important;
      }
    }
  }
}  
