.threeDotsMenuWrapper {
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
  .triggerWrapper {
    cursor: pointer;
  }
  .contentWrapper {
    z-index: 1000;
    width: 280px;
    display: none;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 4%);
    top: 62px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background: #fff;
    text-align: center;
  }
}

.menuWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  li {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    cursor: pointer;
    padding: 12px 22px;
    a {
      text-decoration: none;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #eeeeee;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.01);
    }
    span {
      margin-left: 15px;
    }
  }
}

.dialogWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-size: 18px;
  padding: 24px 39px;
  .header {
    font-weight: 600;
    margin-bottom: 24px;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    .button {
      cursor: pointer;
      border: none;
      background: transparent;
      width: 185px;
      font-size: 18px;
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 866px) {
 .threeDotsMenuWrapper {
    .contentWrapper {
      transform: translate(-90%, 4%);
      top: 28px;
      .arrow {
        display: none;
      }
    }
  }
}
