.dialogWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-size: 18px;
  padding: 30px 24px 24px 24px;
  position: relative;
  .content {
    .head {
      text-align: center;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
      .imageContainer {
        border: 1px solid #ccc;
        img {
          min-width: calc(30vw - 300px);
          min-height: calc(30vh - 100px);
          max-height: calc(100vh - 300px);
          max-width: 100%;
          object-fit: contain;
          border: 1px solid #ccc;
        }
      }
      .aspectBtns {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .aspectBtn {
          cursor: pointer;
          margin-left: 8px;
          background-color: #eeeeee;
          color: #666666;
          text-align: center;
          border: 1.5px solid #ffffff;
          border-radius: 13px;
          padding: 4px 16px;
          font-size: 12px;
          &:first-child {
            margin-left: 0px;
          }
          &.active {
            background-color: #666666;
            color: #ffffff;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    .btn {
      margin-left: 5px;
      &.uploadImage {
        cursor: pointer;
        border: 1px solid #000;
        padding: 6px 12px;
        font-weight: 600;
        font-size: 16px;
        border-radius: 5px;
      }
    }
  }
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

// react-image-crop
.dialogWrapper {
  :global(.ReactCrop__crop-selection) {
    border: 1.5px solid #ffffff;
  }
  :global(.ord-nw),
  :global(.ord-ne),
  :global(.ord-sw),
  :global(.ord-se) {
    &::after {
      background-color: #ffffff;
      border: 1px solid #000000;
    }
  }
}

@media screen and (max-width: 767px) {
  .dialogWrapper {
    width: 100vw;
    height: 100vh;
    max-height: -webkit-fill-available;  
    overflow-y: scroll;
    display: flex;
    justify-content: unset;
    flex-direction: column;
    padding: 0;
    padding-bottom: 30px;
    border-radius: 0;
  }

  .header {
    height: 60px;
    display: flex;
    padding: 10px 0;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #eee;
    .cancel {
      margin-left: 10px;
      font-size: 12px;
      color: #0abc71;
    }
    span {
      margin: 0 auto;
      font-weight: 600;
      font-size: 14px;
    }
    .submit {
      margin-right: 10px;
      button {
        font-size: 12px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    .aspectBtns {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .aspectBtn {
        cursor: pointer;
        margin-left: 8px;
        background-color: #eeeeee;
        color: #666666;
        text-align: center;
        border: 1.5px solid #ffffff;
        border-radius: 13px;
        padding: 4px 16px;
        font-size: 12px;
        &:first-child {
          margin-left: 0px;
        }
        &.active {
          background-color: #666666;
          color: #ffffff;
        }
      }
    }
    .imageContainer {
      border: 1px solid #ccc;
      img {
        border: 1px solid #ccc;
        pointer-events: none;
        max-height: 100% !important;
        width: 100%;
      }
      :global(.ReactCrop__drag-handle) {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }
}
