.followSubscriptionButtons {
  width: 100%;
  .followerShipCtrl {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    margin-top: 10px;
    .followGroup {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      button {
        width: 100%;
      }
    }
    .subButton {
      position: relative;
    }
  }
}
