.progressIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    transform-origin: 50% 50%;
    transform: rotate(-90deg);
  }
  svg circle {
    transition: stroke-dashoffset 1s linear;
  }

  .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
