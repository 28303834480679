.profileWrapper {
  padding: 10px 24px 0 24px;
  border-bottom: 1px solid #ececec;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.profileWrapperBorderNone {
  border: none;
}

.firstLine {
  width: 100%;
  display: flex;
  flex-direction: row;

  .meterDisableLabel {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 21px;
    background: #F7F8FD;
    border-radius: 8px;
    margin-left: 15px;
    text-align: center;
    font-family: Hiragino Kaku Gothic Pro;
    font-style: normal;
  }
  .uploadImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .profilePicGroup {
    position: relative;
    width: 86px;
    height: 86px;
    margin-bottom: 8px;
    .profilePic {
      overflow: hidden;
      border-radius: 100%;
      width: 100%;
      height: 100%;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .emotion {
      position: absolute;
      bottom: -10px;
      right: -10px;
      max-width: 26px;
      z-index: 2;
    }

    .profilePicOverlay {
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      border-radius: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: opacity 0.1s ease-out;
      &:hover {
        opacity: 1;
      }
      .camera {
        filter: invert(100%);
      }
    }
  }
  .editProfilePicLabel {
    cursor: pointer;
    font-weight: 600;
    font-size: 10px;
    color: #0abc71;
    text-align: center;
  }

  .badgeGroup {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    height: fit-content;
    .badge {
      flex-shrink: 0;
      background: #ffffff;
      border-radius: 8px;
    }
    .help {
      position: relative;
      cursor: pointer;
      flex-shrink: 0;
      color: #ffffff;
      font-weight: 600;
      font-size: 9px;
      border-radius: 100%;
      height: 22px;
      width: 22px;
      background-color: #cccccc;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      &:hover {
        .tooltip {
          visibility: visible;
        }
      }
      .tooltip {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #ffffff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        bottom: 120%;
        left: 50%;
        margin-left: -60px;
      }
    }
  }

  .statsGroup {
    display: flex;
    align-items: center;
    padding: 8px;
    flex-wrap: wrap;
    margin-left: 15px;
    &.lessMargin {
      padding: 4px 8px;
      .stats {
        .statsLeft {
          margin-right: 6px;
        }
      }
    }
    &.statsGroupCtrl {
      margin-top: 10px;
      width: 100%;
    }
    .stats {
      &:not(:last-child) {
        margin-right: 30px;
      }
      .statsTop {
        display: flex;
        align-items: center;
        .statsLeft {
          display: flex;
          position: relative;
          margin-right: 7px;
        }
        .statsRight {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .statsText {
            zoom: 0.8;
            font-size: 10px;
            color: #666666;
            -webkit-text-size-adjust: none;
            white-space: nowrap;
          }
          .statsNum {
            font-weight: 600;
            font-size: 15px;
            color: #666666;
            white-space: nowrap;
          }
        }
      }
      .statsBottom {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  &.infoPage .statsGroup {
    margin-left: 5px;
  }
}

.userNameGroup {
  width: 100%;
  margin-top: 12px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  .userName {
    display: flex;
    align-items: center;
    color: #000000;
    font-family: Hiragino Kaku Gothic Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    margin: unset;
    margin-right: 10px;
  }
  .userNameIcon {
    margin-left: 8px;
    display: flex;
  }

  .country {
    font-family: Hiragino Kaku Gothic Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    margin-top: 2px;
    display: flex;
    align-items: center;
    span {
      margin-right: 5px;
      cursor: auto;
    }
    a img {
      cursor: pointer;
    }
  }
}

.userJob {
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}

.extraContainer {
  width: 100%;
}

.dialogWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 18px;
  overflow: hidden;
  max-width: 820px;
  .closeBtn {
    position: absolute;
    top: 16px;
    right: 16px;
    filter: invert(100%);
  }
  .container {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 80px 60px;
  }
  .descriptionSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 23px;
    min-width: 335px;
    .header {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 16px;
    }
    .subheader {
      font-size: 16px;
      color: #666666;
      margin-bottom: 24px;
    }
    .smallText {
      font-size: 12px;
      color: #666666;
      margin-bottom: 24px;
    }
    .nextBtn {
      font-weight: 600;
      font-size: 16px;
      border: 1px solid #000000;
      border-radius: 5px;
      width: 298px;
      text-align: center;
      height: 46px;
      cursor: pointer;
      transition: background-color 0.15s ease-out, color 0.15s ease-out;
      background-color: #ffffff;
      &:hover {
        background-color: #000000;
        color: #ffffff;
      }
    }
    .completeBtn {
      background: #0abc71;
      border: 1px solid #0abc71;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      width: 298px;
      text-align: center;
      height: 46px;
      cursor: pointer;
    }
  }
  .visualSection {
    flex-shrink: 0;
    min-width: 327px;
  }
  .badges {
    margin-bottom: 16px;
  }
  .feature {
    max-width: 327px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    padding: 9px 9px;
    &:not(:last-child) {
      margin-bottom: 13px;
    }
    .featureIcon {
      margin-right: 2px;
      margin-top: -4px;
    }
    .descGroup {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .descTitle {
        font-weight: 600;
        font-size: 14px;
      }
      .descText {
        font-size: 12px;
        color: #666666;
      }
    }
  }
}

@media screen and (max-width: 864px) {
  .dialogWrapper {
    overflow-y: scroll;
    width: 440px;
    min-height: 700px;
  }
  .container {
    height: 700px;
    flex-direction: column-reverse;
    .visualSection {
      margin-bottom: 30px;
    }
    .descriptionSection {
      margin-right: 0;
    }
  }
  .dots {
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .profileWrapper {
    padding: 5px 15px 0 15px;
  }
  .firstLine {
    .badge {
      align-items: flex-start !important;
    }
    .statsGroup {
      width: 54%;
      margin-left: 1%;
      .stats {
        width: 30%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        &:not(:last-child) {
          margin-right: 5%;
        }
        .statsTop {
          flex-direction: column;
          .statsLeft {
            margin-right: 0px;
          }
          .statsRight {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .statsText {
              font-size: 11px;
            }
            .statsNum {
              margin-top: 2px;
              font-weight: 600;
              font-size: 11px;
            }
          }
        }
        .statsBottom {
          font-size: 11px;
          line-height: 14px;
          margin-bottom: 2px;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
        }
      }
    }
    &.infoPage .statsGroup {
      align-items: flex-start;
    }
    &.infoPage .editProfilePicLabel {
      font-size: 8px;
    }
  }

  .profilePicGroup {
    width: 20%;
    height: 20%;
  }
  .userNameGroup {
    margin-top: 3px;
    margin-bottom: 6px;
    .userName {
      font-size: 20px;
      span {
        margin-left: 6px;
      }
    }
  
    .country {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .userJob {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 375px) {
  .firstLine {
    .statsGroup {
      .stats {
        .statsTop {
          .statsRight {
            .statsText {
              font-size: 10px;
            }
          }
        }
        .statsBottom {
          font-size: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .statsGroup {
    margin-bottom: 8px;
  }
}
