.bioSection {
  width: 100%;
  margin-bottom: 10px;
  .bio {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    overflow: hidden;
    text-overflow: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    &.notShortMode {
      display: flex;
    }
  }
  .readmore {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    font-weight: 600;
    font-size: 11px;
    text-decoration: underline;
    color: #0abc71;
    margin-top: 1px;
  }
}

@media screen and (max-width: 767px) {
  .bioSection {
    .bio {
      font-size: 12px;
      line-height: 18px;
      overflow: hidden;
      text-overflow: initial;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: pre-line;
      &.notShortMode {
        display: flex;
      }
    }
  }
}
